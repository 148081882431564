import React from 'react';

import {LoadingContainer, SvgContainer, SvgIcon, TextContainer} from './styles';

const LoadingStyled = (props) => {
    return (
        <LoadingContainer $css={props.$css} className={props.className}>
            <SvgContainer>
                <SvgIcon />
            </SvgContainer>
            {props.message && (
                <TextContainer $css={props.messageStyle}>{props.message}</TextContainer>
            )}
        </LoadingContainer>
    );
};

export default LoadingStyled;
