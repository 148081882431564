import React from 'react';

import LoadingStyled from '../../../generic/components/LoadingStyled/Loading';
import SubmitButtonStyled from '../../../form/components/SubmitButtonStyled/SubmitButtonStyled';
import CancelButtonStyled from '../../../form/components/CancelButton/CancelButtonStyled';
import {getStateName, formatDollars} from 'utils/helpers';
import TextInputStyled from '../../../form/components/TextInputStyled/TextInputStyled';
import ModalWrapperStyled from '../../../modal/components/ModalWrapperStyled/ModalWrapperStyled';

import {
    ButtonContainer,
    Error,
    LoadingCss,
    ModalCss,
    ModalInner,
    ModalLabel,
    ModalSection,
    ModalSectionBase,
    ModalTitle,
    ModalValue,
    State,
    TextInputCss,
    Total,
    TotalAmount,
    TotalContainer,
} from './styles';

const EditModal = (props) => {
    const vipPackage = props.packageUsed;
    return (
        <ModalWrapperStyled $css={ModalCss} handleClose={props.handleClose}>
            <ModalTitle>
                {`${props.subscriptionId ? 'Edit' : 'Add'} Subscription`}
            </ModalTitle>
            {props.error && <Error>{props.error}</Error>}
            <ModalInner>
                <ModalSection>
                    <ModalLabel>State</ModalLabel>
                    <ModalValue>{vipPackage.region.name}</ModalValue>
                </ModalSection>
                <ModalSectionBase>
                    <ModalLabel>Base Price</ModalLabel>
                    <ModalValue>{formatDollars(vipPackage.base_price)}</ModalValue>
                </ModalSectionBase>
                <TextInputStyled
                    $css={TextInputCss}
                    label="Price Rate (multiplied by Base Price)"
                    handleChange={props.handlePriceUpdate}
                    value={props.priceRate}
                />
                <TotalContainer>
                    <Total>Total</Total>
                    <TotalAmount>
                        {formatDollars(+props.priceRate * +vipPackage.base_price)}
                    </TotalAmount>
                </TotalContainer>
            </ModalInner>
            <ButtonContainer>
                <CancelButtonStyled handleClick={props.handleClose} />
                {props.isCreating || props.isSaving ? (
                    <LoadingStyled $css={LoadingCss} />
                ) : (
                    <SubmitButtonStyled
                        disabled={props.priceRate.match(/-/gi)}
                        submitText="Save"
                        handleClick={props.handleSubmit}
                    />
                )}
            </ButtonContainer>
        </ModalWrapperStyled>
    );
};

export default EditModal;
