import {createSelector} from 'reselect';
import {isEmpty, get} from 'utils/helpers';
import {nest} from 'd3-collection';
import {sum} from 'd3-array';

import {selectListingIdParam} from 'features/listings/selectors';

const getVipPackages = (state) => state.vip.packages;
const getVipPackageIdParam = (state, props) => get(props, 'match.params.packageId');

const getVipSubscriptions = (state) => get(state, 'vip.subscriptions') || {};
const getSubscriptionResults = (state, props) => state.vip.subscriptionResults;

export const selectIterableVipSubscriptions = createSelector(
    [getVipSubscriptions],
    (subscriptions) => {
        if (isEmpty(subscriptions)) {
            return [];
        }

        return Object.keys(subscriptions).map((key) => subscriptions[key]);
    },
);

const selectIterableVipPackages = createSelector(
    [getVipPackages],
    (packages) => !isEmpty(packages) && Object.keys(packages).map((key) => packages[key]),
);

export const selectVipPackagesByRegion = createSelector(
    [selectIterableVipPackages],
    (packages) => {
        const nested = nest()
            .key((d) => get(d, 'region.name'))
            .entries(packages);

        const withTotals = nested.map((obj) => {
            return {
                ...obj,
                available: obj.values.reduce((acc, cur) => {
                    const counts = cur.subscription_counts || {};
                    if (!counts.active && !counts['auto-paused'] && !counts.offer) {
                        acc += 1;
                    }
                    return acc;
                }, 0),
            };
        });

        return withTotals;
    },
);

export const selectVipRegions = createSelector(
    [selectVipPackagesByRegion],
    (packages) => {
        return packages.map((pkg) => pkg.key);
    },
);

export const selectVipPackageIdParam = createSelector(
    [getVipPackageIdParam],
    (packageId) => packageId,
);

export const selectVipSubscriptionsByListingId = createSelector(
    [selectListingIdParam, selectIterableVipSubscriptions],
    (listingId, subscriptions) => {
        if (isEmpty(subscriptions)) return [];
        return subscriptions.filter(
            (subscription) => subscription.listing_id === listingId,
        );
    },
);

export const getVipFlatSubscriptions = (state) => state.vipFlat.subscriptions;
