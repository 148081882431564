import {COLORS, darken} from 'core/styles';
import styled, {css} from 'styled-components';

export const modalFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
`;

export const ModalLabel = styled.div`
    font-weight: 500;
    color: ${COLORS.grayFont};
    margin-bottom: 5px;
`;

export const ModalSection = styled.div`
    flex: 1;
    text-align: left;
    margin-right: 10px;
    margin-bottom: 15px;
`;

export const ModalSectionBase = styled(ModalSection)`
    max-width: 90px;
`;

export const ModalCss = css`
    text-align: left;
    max-width: 550px;
`;

export const ModalTitle = styled.h5`
    margin-left: 15px;
`;

export const ModalInner = styled.div`
    background-color: ${COLORS.grayLight};
    border: 1px solid ${COLORS.grayLight};
    margin: 15px;
    padding: 30px 20px;
`;

export const ModalValue = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`;

export const State = styled.div`
    display: inline;
    font-weight: 500;
`;

export const TextInputCss = css`
    display: inline-block;
`;

export const modalFlexBottom = styled(modalFlex)`
    justify-content: flex-start;
`;

export const stateList = styled.div`
    display: flex;
    flex-flow: row wrap;
`;

export const TotalContainer = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: ${COLORS.highlight};
    align-items: center;
    padding: 15px;
    border-radius: 2px;
    border: 1px solid ${darken(COLORS.grayLight, 15)};
`;

export const Total = styled.h3`
    margin: 0;
`;

export const TotalAmount = styled.div`
    font-weight: bold;
    font-size: 15px;
`;

export const ButtonContainer = styled.div`
    text-align: right;
    margin: 0 15px;
`;

export const LoadingCss = css`
    max-width: 20px;
`;

export const Error = styled.div`
    color: ${COLORS.danger};
    font-weight: bold;
    margin: 0 15px;
`;
