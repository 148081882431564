import {COLORS, darken} from 'core/styles';
import styled, {css} from 'styled-components';
import LoadingIcon from 'assets/img/icons/loading.svg';

export const LoadingContainer = styled.div`
    display: 'inline-block';
    ${(props) => (props.$css ? props.$css : '')}
`;

export let SvgContainer = styled.div`
    display: 'inline-block',
    verticalAlign: 'middle'
`;

let rotate = css`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
`;

export let SvgIcon = styled(LoadingIcon)`
    animation: 300ms linear infinite,
    width: '100%',
    height: '100%',
    display: 'inline-block',
    verticalAlign: 'middle',
    maxWidth: '45px',
    maxHeight: '45px'
`;

export let TextContainer = styled.div`
    color: ${darken(COLORS.grayFont, 15)};
    opacity: 0.6;
    font-weight: bold;
    font-size: 14px;
    margin-left: 15px;
    display: inline-block;
    vertical-align: middle;
    ${(props) => (props.$css ? props.$css : '')}
`;
