import React, {useState, useRef} from 'react';
import {connect, useSelector} from 'react-redux';
import {withRouter} from 'utils/helpers';

import {selectIterableVipSubscriptions} from 'features/vip/selectors';
import {selectListingById} from 'features/listings/selectors';
import {isEmpty, getDetailedError} from 'utils/helpers';
import {editVipSubscription, createVipSubscription} from 'features/vip/actions';
import {get} from 'utils/helpers';
import EditModal from 'features/vip/components/EditModal/EditModal';
import {useParams} from 'next/navigation';

let EditModalContainer = (props) => {
    const subs = [...props.subscriptions, ...props.subscriptionResults];
    const subscriptionInitalValue = subs.find((sub) => sub.id === props.subscriptionId);

    const [subscription, setSubscription] = useState(subscriptionInitalValue);
    const [priceRate, setPriceRate] = useState(
        !props.subscriptionId ? '1' : get(subscription, 'price_multiplier'),
    );
    const [packageUsed, setPackageUsed] = useState(
        props.subscriptionId
            ? get(subscription, 'package')
            : props.packages[props.packageId],
    );
    const [error, setError] = useState(null);

    const {listingId} = useParams();
    const listing = useSelector((state) => selectListingById(state, listingId));

    const addSubscription = () => {
        const subscription = {
            package_id: props.packageId,
            account_id: listing.account_id,
            listing_id: listingId,
            price_multiplier: priceRate,
        };
        props
            .createVipSubscription(subscription)
            .then((data) => {
                props.handleClose();
            })
            .catch((e) => {
                setError(getDetailedError(e));
            });
    };

    const editSubscription = () => {
        props
            .editVipSubscription(props.subscriptionId, {
                price_multiplier: priceRate,
            })
            .then(() => props.handleClose())
            .catch((e) => {
                setError(e && e.message ? e.message : 'Could not edit subscription');
            });
    };
    const handleSubmit = () => {
        if (props.subscriptionId) {
            return editSubscription();
        }

        return addSubscription();
    };
    const handlePriceUpdate = (e) => {
        const {value} = e.target;
        /**
         * Check for negative numbers
         */

        if (value.match(/-/gi)) {
            setPriceRate(e.target.value);
            setError('Only positive numbers allowed for Price Rate');
            return;
        }

        setError(null);
        setPriceRate(e.target.value);
    };
    return (
        <EditModal
            {...props}
            error={error}
            priceRate={priceRate}
            subscription={subscription}
            packageUsed={packageUsed}
            listingId={listingId}
            listing={listing}
            handlePriceUpdate={handlePriceUpdate}
            handleSubmit={handleSubmit}
        />
    );
};

EditModalContainer = connect(
    (state, ownProps) => {
        return {
            isCreating: state.vip.isCreating,
            isSaving: state.vip.isSaving,
            packages: state.vip.packages,
            subscriptions: selectIterableVipSubscriptions(state),
            subscriptionResults: state.vip.subscriptionResults,
        };
    },
    {
        editVipSubscription,
        createVipSubscription,
    },
)(EditModalContainer);

export default withRouter(EditModalContainer);
