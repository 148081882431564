import Link from 'next/link';
import styled, {css} from 'styled-components';

import {COLORS, lighten, darken, bp} from 'core/styles';
import TableArrow from 'assets/img/icons/table-arrow.svg';

export const TableCellDiv = styled.div`
    padding-left: 10px;
    position: relative;
    display: inline-block;
    flex: ${(props) => (props.flex ? props.flex : 1)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${(props) => (props.$css ? props.$css : '')}
`;

export const cell = (props) => {
    return Object.assign(
        {
            paddingLeft: '10px',
            position: 'relative',
            display: 'inline-block',
            flex: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        props.style,
    );
};

export let headerRow = {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 0,
    flexWrap: 'wrap',
    width: '100%',
    backgroundColor: lighten(COLORS.gray, 5),
    height: '45px',
    alignItems: 'center',
    padding: '0 15px',
};

export let HeaderRowStyled = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-wrap: wrap;
    width: 100%;
    background-color: ${lighten(COLORS.gray, 5)};
    height: 45px;
    align-items: center;
    padding: 0 15px;
`;

export const row = (props) => {
    return Object.assign(
        {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 0,
            flexWrap: 'wrap',
            width: '100%',
            alignItems: 'center',
            paddingLeft: '15px',
            paddingRight: '15px',
            borderBottom: `1px solid ${darken(COLORS.grayLight, 3)}`,
        },
        props.style,
    );
};

export const RowDiv = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid ${darken(COLORS.grayLight, 3)};
    ${(props) => (props.$css ? props.$css : '')};
`;

/*
    const active = activeKey && (activeKey === sortKey || activeKey.slice(1) === sortKey);
    const reversed = sortKey && active && activeKey.slice(0, 1) === '!';

    const classes = classReducer({
        'table__cell--header': true,
        sortable: sortKey,
        'sortable--reverse': reversed,
        active,
        [props.childClassName]: props.childClassName
    });
*/

const isHeaderCellActive = (props) => {
    const {activeKey, sortKey} = props;
    return activeKey && (activeKey === sortKey || activeKey.slice(1) === sortKey);
};

export const HeaderCellStyled = styled.div`
    cursor: ${(props) => (props.sortKey ? 'pointer' : 'auto')};
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipses;
    position: relative;
    color: ${(props) =>
        isHeaderCellActive(props) ? '#fff' : darken(COLORS.grayLight, 30)};
    text-decoration: ${(props) => (isHeaderCellActive(props) ? 'underline' : 'none')};
    padding-left: 10px;
    font-weight: 900;
    flex: ${(props) => (props.flex ? props.flex : 1)};
    font-size: 8px;
    text-transform: uppercase;
    height: 100%;
    display: flex;
    align-items: center;
    ${bp('lg')} {
        font-size: 9px;
    }

    ${(props) => (props.$css ? props.$css : '')}
`;

export const arrow = (props) => {
    const {activeKey, sortKey} = props;

    const active = activeKey && (activeKey === sortKey || activeKey.slice(1) === sortKey);
    const reversed = sortKey && active && activeKey.slice(0, 1) === '!';

    let styles = {
        marginLeft: '5px',
        width: '12px',
        height: '15px',
        display: 'inline-block',
        marginRight: '10px',
    };

    if (reversed) {
        styles = {
            ...styles,
            transform: 'rotate(180deg)',
            transformOrigin: '50% 50%',
        };
    }

    return styles;
};

export let arrowSvg = {
    width: '100%',
    height: '100%',
};

const isReversed = (props) => {
    const {activeKey, sortKey} = props;
    const active = activeKey && (activeKey === sortKey || activeKey.slice(1) === sortKey);
    return sortKey && active && activeKey.slice(0, 1) === '!';
};

export const ArrowContainer = styled.div`
    margin-left: 5px;
    width: 12px;
    height: 15px;
    display: inline-block;
    margin-right: 10px;
    transform: ${(props) => (isReversed(props) ? 'rotate(180deg)' : 'none')};
    transform-origin: ${(props) => (isReversed(props) ? '50% 50%' : 'none')};
`;

export let ArrowSvgStyled = styled(TableArrow)`
    width: 100%;
    height: 100%;
`;

export let cellInner = {
    fontSize: '9px',
    display: 'inline-block',
    verticalAlign: 'middle',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    paddingTop: '2px',
    paddingBottom: '2px',
};

export let CellInnerStyled = styled.div`
    font-size: 9px;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 2px;
    padding-bottom: 2px;
`;

export let link = {
    fontWeight: 'bold',
    fontSize: '13px',
    color: COLORS.primary,
    textDecoration: 'underline',
    margin: '5px 0',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};

const LinkMixin = css`
    font-weight: bold;
    font-size: 13px;
    color: ${COLORS.primary};
    text-decoration: underline;
    margin: 5px 0;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const LinkStyledDiv = styled.div`
    ${LinkMixin}
`;

export const LinkStyled = styled(Link)`
    ${LinkMixin}
`;

export let address = {
    color: COLORS.grayFont,
    fontSize: '11px',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};

export let AddressDiv = styled.div`
    color: ${COLORS.grayFont};
    font-size: 11px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export let upper = {
    color: darken(COLORS.grayFont, 10),
    fontSize: '10px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
};

export const UpperCss = css`
    color: ${darken(COLORS.grayFont, 10)};
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
`;

export let UpperDiv = styled.div`
    ${UpperCss}
`;

export let status = (status) => {
    let styles = {
        opacity: 0.7,
        color: COLORS.grayFont,
        fontSize: '11px',
        marginBottom: '3px',
        fontWeight: 'bold',
    };

    if (status === 'draft') {
        styles = {
            ...styles,
            color: COLORS.grayFont,
        };
    }

    if (status === 'submitted') {
        styles = {
            ...styles,
            color: darken(COLORS.warning, 7),
        };
    }

    if (status === 'published' || status === 'active') {
        styles = {
            ...styles,
            color: COLORS.successAlt,
        };
    }

    if (status === 'user-canceled' || status === 'auto-paused') {
        styles = {
            ...styles,
            color: COLORS.danger,
        };
    }

    return styles;
};

const getStatusColor = (status) => {
    let color;
    switch (status) {
        case 'draft':
            color = COLORS.grayFont;
            break;
        case 'submitted':
            color = darken(COLORS.warning, 7);
            break;
        case 'published':
        case 'active':
            color = COLORS.successAlt;
            break;
        case 'user-canceled':
        case 'auto-paused':
            color = COLORS.danger;
            break;
        default:
            color = COLORS.grayFont;
            break;
    }
    return color;
};

export let StatusStyled = styled.div`
    opacity: 0.7;
    color: ${(props) => getStatusColor(props.status)};
    font-size: 11px;
    margin-bottom: 3px;
    font-weight: bold;
`;

export let listingName = {
    fontWeight: 'bold',
    fontSize: '12px',
    color: COLORS.primary,
    textDecoration: 'underline',
    marginBottom: '5px',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};

export const ListingNameLink = styled(Link)`
    font-weight: bold;
    font-size: 12px;
    color: ${COLORS.primary};
    text-decoration: underline;
    margin-bottom: 5px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    whitespace: nowrap;
`;

export let reviews = {
    color: COLORS.grayFont,
    fontWeight: 500,
    fontSize: '11px',
};

export let ReviewsDiv = styled.div`
    color: ${COLORS.grayFont};
    font-weight: 500;
    font-size: 11px;
`;

export let stacked = {
    color: COLORS.grayFont,
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: 1.65,
};

export const Stacked = styled.div`
    color: ${COLORS.grayFont};
    font-size: 11px;
    font-weight: 500;
    line-height: 1.65;
`;

export let amount = (amount) => {
    let styles = {
        lineHeight: 1.65,
        fontWeight: 'bold',
        fontSize: '12px',
        color: COLORS.grayFont,
    };

    if (amount > 0) {
        styles = {
            ...styles,
            color: darken(COLORS.success, 60),
        };
    }

    if (amount < 0) {
        styles = {
            ...styles,
            color: COLORS.danger,
        };
    }

    if (amount === 0) {
        styles = {
            ...styles,
            opacity: 0.8,
        };
    }

    return styles;
};

const getColorStyle = (amount) => {
    if (amount > 0) return darken(COLORS.success, 60);
    else if (amount < 0) return COLORS.danger;
    else return COLORS.grayFont;
};

export let AmountCss = css`
    line-height: 1.65;
    font-weight: bold;
    font-size: 12px';
    color: ${(props) => getColorStyle(props.amount)};
    opacity: ${(props) => (props.amount === 0 ? 0.8 : 1)};
`;
